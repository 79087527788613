import React from "react";
// If you're importing the image, uncomment the next line
// import underConstructionImage from './assets/under_construction.png';

const UnderConstructionPage = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full view height
      }}
    >
      <img
        src="/images/under_construction.jpg"
        alt="Under Construction"
        style={{ maxWidth: "100%", maxHeight: "100vh" }}
      />
    </div>
  );
};

export default UnderConstructionPage;
