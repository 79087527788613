// src/data/sidebarMenuItems.ts
import { MenuItem } from "../types/menuTypes";

export const sidebarMenuItems: MenuItem[] = [
  {
    name: "Programe",
    subMenu: [
      { name: "Regulamente", path: "/programe/regulamente" },
      { name: "Calendar activități", path: "/programe/calendar" },
      { name: "Evenimente centru local", path: "/programe/evenimente" },
      { name: "Standarde activitate", path: "/programe/standarde-activitate" },
      { name: "Fișe de activități", path: "/programe/fise-de-activitate" },
      { name: "Ghid de evealuarte", path: "/programe/gid-evaluare" },
    ],
  },
  {
    name: "Parteneri",
    subMenu: [{ name: "Baza de date", path: "/parteneri/baza-de-date" }],
  },
  {
    name: "Safe from Harm",
    subMenu: [
      { name: "Prezentare", path: "/sfh/prezentare" },
      { name: "Acte", path: "/sfh/acte" },
    ],
  },
  {
    name: "Financiar",
    subMenu: [
      { name: "Cotizații", path: "/financiar/prezentare" },
      { name: "Buget", path: "/financiar/buget" },
      { name: "Proceduri", path: "/financiar/proceduri" },
      { name: "Oportunități finanțare", path: "/financiar/finantare" },
    ],
  },
  {
    name: "Sediu",
    subMenu: [
      { name: "Regulament", path: "/sediu/regulament" },
      { name: "Orar + Calendar", path: "/sediu/orar" },
      { name: "Instrucțiuni", path: "/sediu/instructiuni" },
      { name: "Inventar", path: "/sediu/inventar" },
      { name: "Access", path: "/sediu/access" },
    ],
  },
  {
    name: "Resurse umane",
    subMenu: [
      { name: "Organigrama adulți", path: "/resurse-umane/organigrama-adulti" },
      {
        name: "Proceduri înscriere",
        path: "/resurse-umane/proceduri-inscriere",
      },
      { name: "Welcome Kit", path: "/resurse-umane/welcome-kit" },
      { name: "Cod de Conduită", path: "/resurse-umane/cod-de-conduita" },
      { name: "Stagii", path: "/resurse-umane/stagii" },
      { name: "MOVIS", path: "/resurse-umane/MOVIS" },
      {
        name: "Fișe posturi",
        path: "/resurse-umane/fise-posturi",
      },
    ],
  },
  {
    name: "Consiliu",
    subMenu: [
      { name: "Acte", path: "/consiliu/acte" },
      { name: "Minute ședințe", path: "/consiliu/sedinte" },
      { name: "Convocări + Procese verbale", path: "/consiliu/convocari" },
      {
        name: "Copii buletine",
        path: "/consiliu/copii-buletine",
      },
    ],
  },
];
