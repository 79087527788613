import React, { useEffect, useMemo, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Tooltip,
  LinearProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useUser } from "../../contexts/UserContext";
import UserService from "../../services/UserService";
import {
  AdminPanelSettings,
  AdminPanelSettingsOutlined,
  Lock,
  LockOpen,
  SupervisedUserCircle,
  SupervisorAccount,
  Group,
} from "@mui/icons-material";
import { log } from "console";

interface User {
  uid: string;
  email: string;
  displayName: string;
  customClaims: any;
  disabled: boolean;
}

const UserManagement: React.FC = () => {
  const { user } = useUser();
  const [users, setUsers] = useState<User[]>([]);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [localCenterName, setLocalCenterName] = useState<string>("Vest");
  const [createAdminUser, setCreateAdminUser] = useState<boolean>(false);
  const [isModerator, setIsModerator] = useState<boolean>(false);
  const [isCouncil, setIsCouncil] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    const data = await UserService.listUsers();
    console.log(data);
    setUsers(data.users || []);
    setLoading(false);
  };

  const handleCreateUser = async () => {
    setLoading(true);
    await UserService.createUser(
      email,
      firstName,
      lastName,
      localCenterName,
      createAdminUser,
      isModerator,
      isCouncil
    );
    setEmail("");
    setFirstName("");
    setLastName("");
    setLocalCenterName("Vest");
    setCreateAdminUser(false);
    setIsModerator(false);
    setIsCouncil(false);
    setOpen(false);
    fetchUsers();
    setLoading(false);
    //TODO send reset password email
  };

  const handleDisableUser = async (uid: string) => {
    setLoading(true);
    await UserService.disableUser(uid);
    fetchUsers();
    setLoading(false);
  };

  const handleEnableUser = async (uid: string) => {
    setLoading(true);
    await UserService.enableUser(uid);
    fetchUsers();
    setLoading(false);
  };

  const handleToggleAdmin = async (uid: string, isAdmin: boolean) => {
    setLoading(true);
    if (isAdmin) {
      await UserService.removeAdmin(uid);
    } else {
      await UserService.makeAdmin(uid);
    }
    fetchUsers();
    setLoading(false);
  };

  const handleToggleModerator = async (uid: string, isModerator: boolean) => {
    setLoading(true);
    if (isModerator) {
      await UserService.removeModerator(uid);
    } else {
      await UserService.makeModerator(uid);
    }
    fetchUsers();
    setLoading(false);
  };

  const handleToggleCouncil = async (uid: string, isCouncil: boolean) => {
    setLoading(true);
    if (isCouncil) {
      await UserService.removeCouncil(uid);
    } else {
      await UserService.makeCouncil(uid);
    }
    fetchUsers();
    setLoading(false);
  };

  const columns: GridColDef[] = [
    { field: "email", headerName: "Email", width: 250 },
    { field: "displayName", headerName: "Name", width: 200 },
    {
      field: "isAdmin",
      headerName: "Admin",
      width: 150,
      renderCell: (params) => (params.row.customClaims?.isAdmin ? "Yes" : "No"),
    },
    {
      field: "isModerator",
      headerName: "Moderator",
      width: 150,
      renderCell: (params) =>
        params.row.customClaims?.isModerator ? "Yes" : "No",
    },
    {
      field: "isCouncil",
      headerName: "Council Member",
      width: 150,
      renderCell: (params) =>
        params.row.customClaims?.isCouncil ? "Yes" : "No",
    },
    {
      field: "disabled",
      headerName: "Enabled",
      width: 150,
      renderCell: (params) => (params.row.disabled ? "No" : "Yes"),
    },
  ];

  if (user?.isAdmin || user?.isSuperAdmin) {
    columns.push({
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <>
          <Tooltip
            title={
              params.row.customClaims?.isAdmin ? "Remove Admin" : "Make Admin"
            }
          >
            <IconButton
              color="primary"
              onClick={() =>
                handleToggleAdmin(
                  params.row.uid,
                  params.row.customClaims?.isAdmin
                )
              }
            >
              {params.row.customClaims?.isAdmin ? (
                <AdminPanelSettings />
              ) : (
                <AdminPanelSettingsOutlined />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              params.row.customClaims?.isModerator
                ? "Remove Moderator"
                : "Make Moderator"
            }
          >
            <IconButton
              color="primary"
              onClick={() =>
                handleToggleModerator(
                  params.row.uid,
                  params.row.customClaims?.isModerator
                )
              }
            >
              {params.row.customClaims?.isModerator ? (
                <SupervisorAccount />
              ) : (
                <Group />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              params.row.customClaims?.isCouncil
                ? "Remove Council"
                : "Make Council"
            }
          >
            <IconButton
              color="primary"
              onClick={() =>
                handleToggleCouncil(
                  params.row.uid,
                  params.row.customClaims?.isCouncil
                )
              }
            >
              {params.row.customClaims?.isCouncil ? (
                <SupervisedUserCircle />
              ) : (
                <SupervisorAccount />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={params.row.disabled ? "Enable User" : "Disable User"}>
            <IconButton
              color="secondary"
              onClick={() =>
                params.row.disabled
                  ? handleEnableUser(params.row.uid)
                  : handleDisableUser(params.row.uid)
              }
              style={{ color: "red" }}
            >
              {params.row.disabled ? <LockOpen /> : <Lock />}
            </IconButton>
          </Tooltip>
        </>
      ),
    });
  }

  const rows = useMemo(() => {
    return users.map((user) => ({
      id: user.uid,
      ...user,
    }));
  }, [users]);

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4">User Management</Typography>
        <Typography variant="body1">
          <strong>Super Admin:</strong> Can view and manage all users, edit user
          details, and import users.
        </Typography>
        <Typography variant="body1">
          <strong>Admin:</strong> Can manage users in their local center, edit
          user details.
        </Typography>
        <Typography variant="body1">
          <strong>Moderator:</strong> Can moderate content and user activities.
        </Typography>
        <Typography variant="body1">
          <strong>Council Member:</strong> Can participate in council decisions
          and meetings.
        </Typography>
      </Box>
      {user?.isAdmin || user?.isSuperAdmin ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Create User
        </Button>
      ) : null}
      {loading && <LinearProgress />}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create User</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="local-center-label">Local Center</InputLabel>
                <Select
                  labelId="local-center-label"
                  value={localCenterName}
                  onChange={(e) => setLocalCenterName(e.target.value as string)}
                >
                  <MenuItem value="Vest">Vest</MenuItem>
                  <MenuItem value="Nord">Nord</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={createAdminUser}
                    onChange={(e) => setCreateAdminUser(e.target.checked)}
                  />
                }
                label="Admin"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isModerator}
                    onChange={(e) => setIsModerator(e.target.checked)}
                  />
                }
                label="Moderator"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCouncil}
                    onChange={(e) => setIsCouncil(e.target.checked)}
                  />
                }
                label="Council Member"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateUser} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={2}>
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid loading={loading} rows={rows} columns={columns} />
        </div>
      </Box>
    </Container>
  );
};

export default UserManagement;
