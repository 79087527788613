import { auth } from "../../firebaseConfig";
import { jwtDecode } from "jwt-decode";

export const isCurrentUserAdmin = async (): Promise<boolean> => {
  const user = auth.currentUser;
  if (user) {
    const idToken = await user.getIdToken();
    const decodedToken: any = jwtDecode(idToken);
    return !!decodedToken.isAdmin;
  }
  return false;
};
