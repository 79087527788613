import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebaseConfig";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { Helmet } from "react-helmet";
import { useUser } from "../../contexts/UserContext";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const CustomCheckboxEditComponent = (params: any) => {
  const { id, value, api, field } = params;

  const handleChange = (event: any) => {
    api.setEditCellValue({ id, field, value: event.target.checked }, event);
  };

  return (
    <Checkbox
      checked={value}
      onChange={handleChange}
      inputProps={{ "aria-label": "Is Consumable" }}
    />
  );
};

const categoryOptions: any = {
  Camp: [
    "Infrastructura",
    "Bucatarie",
    "Prim Ajutor",
    "Mancare",
    "Scule",
    "Corturi",
  ],
  Programe: [
    "Prim Ajutor - DEMO",
    "Papetarie",
    "Boardgames",
    "De legat",
    "Festivalul Luminii",
    "Altele",
  ],
  Sediu: ["Mobila", "Electronice", "Curatenie"],
};

const CustomImageEditComponent = ({ id, api, field }: any) => {
  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileRef = storageRef(storage, `inventory/${Date.now()}_${file.name}`);
    await uploadBytes(fileRef, file);
    const newUrl = await getDownloadURL(fileRef);

    api.setEditCellValue({ id, field, value: newUrl }, event);
    api.commitCellChange({ id, field });
    api.setCellMode(id, field, "view");
  };

  return <input type="file" onChange={handleFileChange} accept="image/*" />;
};

const InventoryPage = () => {
  const [inventoryItems, setInventoryItems] = useState<any>([]);
  const [filteredInventoryItems, setFilteredInventoryItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { user } = useUser();
  const [open, setOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const initialState = {
    id: "",
    name: "",
    quantity: 0,
    category: "",
    subcategory: "",
    owner: "",
    notes: "",
    locationDescription: "",
    condition: "Bună",
    isConsumable: false,
    addedBy: user?.displayName,
    imageUrl: "",
  };

  const [newItem, setNewItem] = useState({ ...initialState });
  const fetchInventoryItems = async () => {
    const querySnapshot = await getDocs(collection(db, "inventoryItems"));
    const items = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id, // Ensure each item has an 'id' field
    }));
    setInventoryItems(items);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (item: any) => {
    setNewItem(item); // Pre-fill the form with the item data
    setOpen(true); // Open the dialog
  };

  const handleSave = async () => {
    if (newItem.id) {
      // It's an edit
      const docRef = doc(db, "inventoryItems", newItem.id);
      await updateDoc(docRef, newItem);
    } else {
      // It's a new addition
      await addDoc(collection(db, "inventoryItems"), newItem);
    }
    await fetchInventoryItems(); // Refresh items
    handleClose(); // Close the dialog
    setNewItem({ ...initialState }); // Reset the form state
  };

  const handleDelete = async (id: any) => {
    const rowRef = doc(db, "inventoryItems", id);
    await deleteDoc(rowRef);
    fetchInventoryItems(); // Refresh the data
  };

  const processRowUpdate = async (newRow: any) => {
    const rowRef = doc(db, "inventoryItems", newRow.id);

    // Assuming your Firestore fields match the newRow object structure
    await updateDoc(rowRef, {
      ...newRow,
    });

    fetchInventoryItems(); // Refresh the data
    return newRow;
  };

  // Update the state as the user types into the form
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewItem({ ...newItem, [event.target.name]: event.target.value });
  };

  const handleOwnerChange = (event: any) => {
    setNewItem({ ...newItem, owner: event.target.value as string });
  };

  const handleLocationChange = (event: any) => {
    setNewItem({
      ...newItem,
      locationDescription: event.target.value as string,
    });
  };

  const handleImageUpload = async (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsSaveDisabled(true);
    const fileRef = storageRef(storage, `inventory/${file.name}`);
    await uploadBytes(fileRef, file)
      .then((snapshot) => {
        console.log("Uploaded a blob or file!");
        setIsSaveDisabled(false);
      })
      .catch((err) => {
        console.error(err);
        setIsSaveDisabled(false);
      });

    const fileUrl = await getDownloadURL(fileRef);
    setNewItem({ ...newItem, imageUrl: fileUrl });
  };

  const handleConditionChange = (event: any) => {
    setNewItem({ ...newItem, condition: event.target.value as string });
  };

  useEffect(() => {
    if (!searchText) {
      setFilteredInventoryItems(inventoryItems);
      return;
    }

    const lowercasedSearchText = searchText.toLowerCase();
    const filtered = inventoryItems.filter(
      (item: any) =>
        item.name.toLowerCase().includes(lowercasedSearchText) ||
        item.locationDescription.toLowerCase().includes(lowercasedSearchText) ||
        item.notes.toLowerCase().includes(lowercasedSearchText)
    );

    setFilteredInventoryItems(filtered);
  }, [searchText, inventoryItems]);

  useEffect(() => {
    fetchInventoryItems();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Nume",
      width: 150,
    },
    {
      field: "quantity",
      headerName: "Cantitate",
      type: "number",
      width: 90,
    },
    {
      field: "category",
      headerName: "Categorie",
      type: "number",
      width: 110,
    },
    {
      field: "subcategory",
      headerName: "Subcategorie",
      width: 110,
    },
    {
      field: "isConsumable",
      headerName: "Consumabil",
      width: 90,
      type: "boolean",

      renderCell: (params: any) => (params.value ? "Da" : "Nu"),
      renderEditCell: (params: any) => (
        <CustomCheckboxEditComponent {...params} />
      ),
    },
    {
      field: "owner",
      headerName: "Centru Local",
      width: 120,
    },
    {
      field: "locationDescription",
      headerName: "Locatie",
      width: 150,
    },
    {
      field: "condition",
      headerName: "Stare",
      width: 70,
    },
    {
      field: "notes",
      headerName: "Observatii",
      width: 150,
    },
    {
      field: "addedBy",
      headerName: "Adaugat de",
      width: 120,
    },
    {
      field: "imageUrl",
      headerName: "Image",
      width: 60,
      renderCell: (params: any) => (
        <img
          src={params.value}
          alt=""
          style={{ width: 50, height: 50, cursor: "pointer" }}
          onClick={() => window.open(params.value, "_blank")}
        />
      ),
      renderEditCell: (params: any) => <CustomImageEditComponent {...params} />,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 70,
      sortable: false,
      editable: false,
      renderCell: (params: any) => (
        <>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(params.row)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete(params.id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box>
      <Helmet>
        <title>Inventar - Scouts Cluj</title>
      </Helmet>
      <Typography variant="h4" component="h2" gutterBottom>
        Inventar
      </Typography>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ margin: 24 }}
      >
        Adaugă
      </Button>
      <TextField
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        label="Search"
        style={{ margin: 16 }}
      />

      <Box sx={{ maxWidth: "calc(100vw - 48px)", overflow: "auto" }}>
        <DataGrid
          rows={filteredInventoryItems}
          columns={columns}
          disableRowSelectionOnClick
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          autoHeight
          sx={{ height: 400, width: "100%", overflow: "auto" }}
        />
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Adaugă un nou element</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Nume"
            type="text"
            fullWidth
            variant="outlined"
            value={newItem.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="quantity"
            label="Cantitate"
            type="number"
            fullWidth
            variant="outlined"
            value={newItem.quantity}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel id="category-label">Categorie</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              name="category"
              value={newItem.category}
              onChange={(event) => {
                const category = event.target.value;
                setNewItem({
                  ...newItem,
                  category,
                  subcategory: "", // Reset subcategory on category change
                });
              }}
              label="Categorie"
            >
              {Object.keys(categoryOptions).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Subcategory Dropdown */}
          <FormControl
            fullWidth
            margin="dense"
            variant="outlined"
            disabled={!newItem.category}
          >
            <InputLabel id="subcategory-label">Subcategorie</InputLabel>
            <Select
              labelId="subcategory-label"
              id="subcategory"
              name="subcategory"
              value={newItem.subcategory}
              onChange={(event) =>
                setNewItem({ ...newItem, subcategory: event.target.value })
              }
              label="Subcategorie"
            >
              {newItem.category
                ? categoryOptions[newItem.category].map((subcategory: any) => (
                    <MenuItem key={subcategory} value={subcategory}>
                      {subcategory}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={newItem.isConsumable}
                onChange={(event) =>
                  setNewItem({ ...newItem, isConsumable: event.target.checked })
                }
                name="isConsumable"
              />
            }
            label="Consumabil"
          />
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel id="owner-label">Centru Local</InputLabel>
            <Select
              labelId="owner-label"
              id="owner"
              name="owner"
              value={newItem.owner}
              onChange={handleOwnerChange}
              label="Centru Local"
            >
              <MenuItem value={"CL Vest"}>CL Vest</MenuItem>
              <MenuItem value={"CL Nord"}>CL Nord</MenuItem>
              <MenuItem value={"Comun"}>Comun</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="notes"
            value={newItem.notes}
            label="Observatii"
            type="text"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel id="locationDescription-label">Locatie</InputLabel>
            <Select
              labelId="locationDescription-label"
              id="locationDescription"
              name="locationDescription"
              value={newItem.locationDescription}
              onChange={handleLocationChange}
              label="Locatie"
            >
              <MenuItem value={"Pod - Camp"}>Pod - Camp</MenuItem>
              <MenuItem value={"Pod - Programe"}>Pod - Programe</MenuItem>
              <MenuItem value={"Pod - Misc"}>Pod - Misc</MenuItem>
              <MenuItem value={"Sub Scari"}>Sub Scari</MenuItem>
              <MenuItem value={"Camera 1"}>Camera 1</MenuItem>
              <MenuItem value={"Camera 2"}>Camera 2</MenuItem>
              <MenuItem value={"Camera 3"}>Camera 3</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel id="condition-label">Stare</InputLabel>
            <Select
              labelId="condition-label"
              id="condition"
              name="condition"
              value={newItem.condition}
              onChange={handleConditionChange}
              label="Stare"
            >
              <MenuItem value={"Bună"}>Bună</MenuItem>
              <MenuItem value={"De reparat"}>De reparat</MenuItem>
            </Select>
          </FormControl>
          <TextField
            type="file"
            onChange={handleImageUpload}
            inputProps={{ accept: "image/*" }} // Accept only images
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={isSaveDisabled} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InventoryPage;
