import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import {
  IconButton,
  Box,
  Toolbar,
  Typography,
  useTheme,
  CssBaseline,
  useMediaQuery,
} from "@mui/material";
import SideBar from "./components/SideBar";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import { ProfileAvatarMenu } from "./components/ProfileAvatarMenu";
import UnderConstructionPage from "./components/UnderConstructionPage";
import { sidebarMenuItems } from "./constants/sidebarMenuItems";
import { SubMenuItem } from "./types/menuTypes";
import { ProtectedRoute } from "./components/ProtectedRoute";
import InventoryPage from "./pages/headquarter/InventoryPage";
import FirebaseAuth from "./pages/auth/Auth";
import { Home } from "./pages/Home";
import { auth } from "./firebaseConfig";
import UserManagement from "./pages/humanResources/UserManagement";
import { isCurrentUserAdmin } from "./pages/auth/utils";

const drawerWidth = 240;

const App: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const admin = await isCurrentUserAdmin();
          setIsUserAdmin(admin);
        }
        setLoading(false);
      });
    };
    checkAdmin();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <UserProvider>
      <Router>
        <CssBaseline />
        <AppBar
          position="sticky"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: "#cf2e2e",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              Resurse Scouts Cluj
            </Typography>
            <ProfileAvatarMenu
              hideName={isSmallScreen}
              handleDrawerToggle={handleDrawerToggle}
            />
          </Toolbar>
        </AppBar>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              marginLeft: { sm: `${drawerWidth}px` },
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <SideBar
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
            <Routes>
              <Route
                path={"/"}
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route path="/signin" element={<FirebaseAuth />} />
              <Route
                path="/sediu/inventar"
                element={
                  <ProtectedRoute>
                    <InventoryPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/resurse-umane/organigrama-adulti"
                element={<UserManagement />}
              />
              {sidebarMenuItems.map((item: any) =>
                !item.subMenu ? (
                  <Route
                    key={item.path}
                    path={item.path}
                    element={
                      <ProtectedRoute>
                        <UnderConstructionPage />
                      </ProtectedRoute>
                    }
                  />
                ) : (
                  item.subMenu.map((subItem: SubMenuItem) => (
                    <Route
                      key={subItem.path}
                      path={subItem.path}
                      element={
                        <ProtectedRoute>
                          <UnderConstructionPage />
                        </ProtectedRoute>
                      }
                    />
                  ))
                )
              )}
            </Routes>
          </Box>
        </Box>
      </Router>
    </UserProvider>
  );
};

export default App;
