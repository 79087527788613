import { useUser } from "../contexts/UserContext";

export const Home = () => {
  const { user } = useUser();

  return (
    <>
      Hello, {user?.firstName}!
      <br />
      {user?.typeName}
      <pre>Is Admin: {user?.isAdmin}</pre>
    </>
  );
};
