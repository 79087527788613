// src/contexts/UserContext.tsx
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { auth, db } from "../firebaseConfig";
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { jwtDecode } from "jwt-decode";

interface User {
  displayName: string | null;
  email: string | null;
  photoURL: string | null;
  isAdmin: boolean;
  isModerator: boolean;
  isCouncil: boolean;
  isSuperAdmin: boolean;
  role?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  localCenterName?: string;
  typeName?: string;
  age?: number;
  dateBirthFormatted?: string;
  townCurrentName?: string;
  phoneNumber?: string;
  cardId?: string;
  professionHeadline?: string;
  feeTenantProductPriceName?: string;
}

interface UserContextType {
  user: User | null;
  setCurrentUser: Dispatch<SetStateAction<User | null>>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user: FirebaseUser | null) => {
        if (user) {
          const idToken = await user.getIdToken();
          const decodedToken: any = jwtDecode(idToken);

          // Fetch additional user data from Firestore
          const userDoc = await getDoc(doc(db, "users", user.uid));
          const userData = userDoc.exists() ? userDoc.data() : {};

          setCurrentUser({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            isAdmin: !!decodedToken.isAdmin,
            isModerator: !!decodedToken.isModerator,
            isCouncil: !!decodedToken.isCouncil,
            isSuperAdmin: !!decodedToken.isSuperAdmin,
            localCenterName: decodedToken.localCenterName,
            ...userData,
          });
          console.log(
            JSON.stringify({
              displayName: user.displayName,
              email: user.email,
              photoURL: user.photoURL,
              isAdmin: !!decodedToken.isAdmin,
              isModerator: !!decodedToken.isModerator,
              isCouncil: !!decodedToken.isCouncil,
              isSuperAdmin: !!decodedToken.isSuperAdmin,
              localCenterName: !!decodedToken.localCenterName,
              ...userData,
            })
          );
        } else {
          setCurrentUser(null);
        }
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user: currentUser, setCurrentUser }}>
      {children}
    </UserContext.Provider>
  );
};
