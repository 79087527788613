// src/firebaseUiInstance.ts
import * as firebaseui from "firebaseui";
import { auth } from "../firebaseConfig";

const firebaseUiInstance = (() => {
  let instance: firebaseui.auth.AuthUI | null = null;

  return {
    getInstance: () => {
      if (!instance) {
        instance = new firebaseui.auth.AuthUI(auth);
      }
      return instance;
    },
  };
})();

export default firebaseUiInstance;
