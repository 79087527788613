import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleAuthProvider, EmailAuthProvider } from "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import { useUser } from "../../contexts/UserContext";
import firebaseUiInstance from "../../instances/firebaseAuthInstance";

const FirebaseAuth: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setCurrentUser } = useUser();
  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (user) {
      navigate(from, { replace: true });
      return;
    }

    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult: any) => {
          const { user } = authResult;
          if (user) {
            setCurrentUser({
              ...user,
              isAdmin: false,
              isModerator: false,
              isCouncil: false,
              isSuperAdmin: false,
            });
            navigate(from, { replace: true });
          }
          return false;
        },
        uiShown: () => {
          document.getElementById("loader")!.style.display = "none";
        },
      },
      signInFlow: "popup",
      signInSuccessUrl: from,
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        {
          provider: EmailAuthProvider.PROVIDER_ID,
          signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
          requireDisplayName: true,
          disableSignUp: {
            status: true,
          },
        },
      ],
      tosUrl: "/terms-of-service",
      privacyPolicyUrl: "/privacy-policy",
    };

    const ui = firebaseUiInstance.getInstance();
    ui.start("#firebaseui-auth-container", uiConfig);

    return () => {
      ui.reset();
    };
  }, [user, from, navigate, setCurrentUser]);

  return (
    <div>
      <h2>Login</h2>
      <div id="firebaseui-auth-container"></div>
      <div id="loader">Loading...</div>
    </div>
  );
};

export default FirebaseAuth;
