// src/components/Sidebar.tsx
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useUser } from "../contexts/UserContext";
import { sidebarMenuItems } from "../constants/sidebarMenuItems";
import { useTheme } from "@mui/material/styles";
import { SwipeableDrawer, useMediaQuery } from "@mui/material";
import { ProfileAvatarMenu } from "./ProfileAvatarMenu";
interface SideBarProps {
  mobileOpen?: boolean;
  handleDrawerToggle: () => void;
}

const SideBar: React.FC<SideBarProps> = ({
  mobileOpen,
  handleDrawerToggle,
}) => {
  const [open, setOpen] = useState<Record<string, boolean>>({});
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerClose: React.ReactEventHandler = () => {
    handleDrawerToggle();
  };
  const handleDrawerOpen: React.ReactEventHandler = () => {
    handleDrawerToggle();
  };

  const handleClick = (name: string) => {
    setOpen((prevOpen) => ({ ...prevOpen, [name]: !prevOpen[name] }));
  };

  const handleSubMenuItemClick = (path: string) => {
    if (!isLargeScreen && mobileOpen) {
      handleDrawerToggle();
    }
  };

  return (
    <Box>
      <SwipeableDrawer
        variant={isLargeScreen ? "permanent" : "temporary"}
        open={mobileOpen}
        sx={{
          width: 240,
          "& .MuiDrawer-paper": {
            width: 240,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxSizing: "border-box",
            pt: `${theme.mixins.toolbar.minHeight}px`,
          },
        }}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        <Box>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{ flexDirection: "column" }}
          ></AppBar>
          <List>
            {sidebarMenuItems.map((item) => (
              <React.Fragment key={item.name}>
                <ListItemButton onClick={() => handleClick(item.name)}>
                  <ListItemText primary={item.name} />
                  {open[item.name] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                {item.subMenu && (
                  <Collapse in={open[item.name]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.subMenu.map((subItem) => (
                        <ListItemButton
                          key={subItem.name}
                          sx={{ pl: 4 }}
                          component={RouterLink}
                          to={subItem.path}
                          onClick={() => handleSubMenuItemClick(subItem.path)}
                        >
                          <ListItemText primary={subItem.name} />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
        <ProfileAvatarMenu handleDrawerToggle={handleDrawerToggle} />
      </SwipeableDrawer>
    </Box>
  );
};

export default SideBar;
