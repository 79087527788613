import { auth } from "../firebaseConfig";

interface CreateUserResponse {
  uid: string;
  email: string;
  displayName: string;
}

interface ListUsersResponse {
  users: Array<{
    uid: string;
    email: string;
    displayName: string;
    customClaims: any;
    disabled: boolean;
  }>;
}

const BASE_URL = "https://europe-west1-resurse-scouts-cluj.cloudfunctions.net";

const UserService = {
  async createUser(
    email: string,
    firstName: string,
    lastName: string,
    localCenterName: string,
    isAdmin: boolean,
    isModerator: boolean,
    isCouncil: boolean
  ): Promise<CreateUserResponse> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/createUser`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          firstName,
          lastName,
          localCenterName,
          isAdmin,
          isModerator,
          isCouncil,
        }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      const data = await response.json();
      console.log(data.passwordResetLink);
      return data.userRecord;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  },

  async listUsers(): Promise<ListUsersResponse> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/listUsers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error listing users:", error);
      throw error;
    }
  },

  async disableUser(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/disableUser`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error disabling user:", error);
      throw error;
    }
  },

  async enableUser(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/enableUser`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error enabling user:", error);
      throw error;
    }
  },

  async makeAdmin(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/makeAdmin`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error making user admin:", error);
      throw error;
    }
  },

  async removeAdmin(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/removeAdmin`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error removing admin:", error);
      throw error;
    }
  },
  async makeModerator(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/makeModerator`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error making user moderator:", error);
      throw error;
    }
  },

  async removeModerator(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/removeModerator`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error removing moderator:", error);
      throw error;
    }
  },

  async makeCouncil(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/makeCouncil`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error making user council member:", error);
      throw error;
    }
  },

  async removeCouncil(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/removeCouncil`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error removing council member:", error);
      throw error;
    }
  },
  async makeSuperAdmin(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/makeSuperAdmin`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error making user super admin:", error);
      throw error;
    }
  },

  async removeSuperAdmin(uid: string): Promise<{ message: string }> {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated");
      const idToken = await user.getIdToken();
      const response = await fetch(`${BASE_URL}/removeSuperAdmin`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ uid }),
      });
      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      return await response.json();
    } catch (error) {
      console.error("Error removing super admin:", error);
      throw error;
    }
  },
};

export default UserService;
