import {
  Box,
  IconButton,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { useState } from "react";
import { useUser } from "../contexts/UserContext";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

interface ProfileAvatarMenuProps {
  hideName?: boolean;
  handleDrawerToggle: () => void;
}

export const ProfileAvatarMenu = ({
  hideName = false,
  handleDrawerToggle,
}: ProfileAvatarMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { user } = useUser();
  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleDrawerToggle();
  };
  const handleLogout = async () => {
    try {
      handleDrawerToggle();
      await signOut(auth);
      console.log("User signed out successfully");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <Box>
      {user ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuClick}
            color="inherit"
          >
            <Avatar
              alt={user?.displayName || "User"}
              src={user?.photoURL || ""}
              style={{ width: 30, height: 30 }}
            />
            {!hideName && (
              <Typography
                variant="subtitle1"
                color="inherit"
                style={{ marginLeft: 10 }}
              >
                {user?.displayName || "User"}
              </Typography>
            )}
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      ) : (
        <Button
          color="inherit"
          onClick={() => {
            handleDrawerToggle();
            navigate("/signin");
          }}
        >
          Login
        </Button>
      )}
    </Box>
  );
};
